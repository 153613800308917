<template>
  <div>
    <Content>
      <div class="main-body">
        <Form
          class="formValidate"
          ref="formValidate"
          :rules="ruleValidate"
          label-position="left"
          :label-width="160"
        >
          <div class="action-area">
            <div style="display:flex;">
              <div class="actBtn cancle" @click="cancle">
                <span v-if="editState == false"
                  ><i class="iconfont iconicon-bianji"></i>&nbsp;编辑</span
                >
                <span v-if="editState == true"
                  ><i class="iconfont iconicon-quxiao"></i>&nbsp;取消</span
                >
              </div>
              <!-- <div
                v-show="editState === false"
                class="actBtn cancle"
                @click="delModal = true"
              >
                <i class="iconfont iconicon-shanchu"></i>
                <span>删除</span>
              </div> -->
              <div
                v-show="editState === true"
                class="actBtn cancle"
                @click="save('formValidate')"
              >
                <i class="iconfont iconicion-baocun"></i>
                <span>保存</span>
              </div>
            </div>
          </div>
          <div
            class="basicInfo"
            v-for="(item, index) in formValidate"
            :key="item.ztdId"
          >
            <div
              class="infoTitle"
              style="border-bottom: 1px solid #DFE3E8;padding-bottom: 10px;"
            >
              基础信息
            </div>
            <div class="infoDetail">
              <FormItem>
                <Row>
                  <Col span="8">
                    <FormItem prop="ztdDeviceNo" label="设备编号">
                      <div v-show="editState == false" class="textfont">
                        {{ item.ztdDeviceNo }}
                      </div>
                      <Input
                        v-show="editState == true"
                        v-model="item.ztdDeviceNo"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="8">
                    <FormItem prop="ztdDeviceType" label="设备类型">
                      <div v-show="editState == false" class="textfont">
                        {{ item.ztdDeviceType }}
                      </div>
                      <Input
                        v-show="editState == true"
                        v-model="item.ztdDeviceType"
                        maxlength="20"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="8">
                    <FormItem prop="ztdMachineModelName" label="设备型号">
                      <div v-show="editState == false" class="textfont">
                        {{ item.ztdMachineModelName }}
                      </div>
                      <Input
                        v-show="editState == true"
                        v-model="item.ztdMachineModelName"
                        maxlength="20"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="8">
                    <FormItem prop="ztdMachineNo" label="机台号">
                      <div v-show="editState == false" class="textfont">
                        {{ item.ztdMachineNo }}
                      </div>
                      <Input
                        v-show="editState == true"
                        v-model="item.ztdMachineNo"
                        maxlength="20"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                  <Col span="8">
                    <FormItem prop="ztdWorkshopId" label="所属车间">
                      <div v-show="editState == false" class="textfont">
                        {{ item.ztdWorkshopName }}
                      </div>
                      <Select
                        v-show="editState == true"
                        v-model="item.ztdWorkshopId"
                        style="width:200px;"
                        @on-change="workShopChange(index)"
                      >
                        <Option
                          v-for="(item, index) in workshops"
                          :value="item.workshopId"
                          :key="index"
                          >{{ item.workshop }}</Option
                        >
                      </Select>
                    </FormItem>
                  </Col>
                  <Col span="8">
                    <FormItem prop="ztdMachineBrand" label="品牌">
                      <div v-show="editState == false" class="textfont">
                        {{ item.ztdMachineBrand }}
                      </div>
                      <Input
                        v-show="editState == true"
                        v-model="item.ztdMachineBrand"
                        placeholder="请输入"
                        style="width:200px;"
                      ></Input>
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col span="8">
                    <FormItem label="入厂日期">
                      <div v-show="editState == false" class="textfont">
                        {{
                          item.ztdFactoryDate === ""
                            ? ""
                            : moment(item.ztdFactoryDate).format("YYYY-MM-DD")
                        }}
                      </div>
                      <DatePicker
                        v-show="editState == true"
                        type="date"
                        placeholder="请选择日期"
                        v-model="item.ztdFactoryDate"
                      ></DatePicker>
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </div>
          </div>
        </Form>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      workshops: [],
      selectDev: [], // 用来放当前设备Id用来删除
      current: 0,
      userId: "", //
      editState: false, // 0 不可编辑 1 可编辑
      formValidate: [],
      breakdownType: 0,
      workdata: [],
      ruleValidate: {
        ztdDeviceNo: [{ required: true, message: " ", trigger: "blur" }],
        ztdMachineNo: [{ required: true, message: " ", trigger: "blur" }],
        ztdDeviceType: [{ required: true, message: " ", trigger: "blur" }],
        ztdMachineModelName: [
          { required: true, message: " ", trigger: "blur" }
        ],
        ztdWorkshopId: [{ required: true, message: " ", trigger: "blur" }],
        ztdMachineBrand: [{ required: true, message: " ", trigger: "blur" }]
      }
    };
  },
  created() {
    this.getDeviceMessage();
  },
  mounted() {
    this.getWorkshops();
  },
  methods: {
    // 获取车间列表
    getWorkshops() {
      const that = this;
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/workshoplist",
        method: "get",
        params: {}
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.workshops = res.data.body.workshoplist;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDeviceMessage() {
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/deviceDetail",
        method: "get",
        params: {
          ztdIdList: JSON.stringify(this.$route.query.selectDeviceList)
        }
      })
        .then((res) => {
          this.formValidate = res.data.body.deviceDetailList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    save() {
      for (let i = 0; i < this.formValidate.length; i++) {
        if (!this.formValidate[i].ztdDeviceNo) {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的设备编号");
          return false;
        } else if (!this.formValidate[i].ztdDeviceType) {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的设备类型");
          return false;
        } else if (!this.formValidate[i].ztdMachineModelName) {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的设备型号");
          return false;
        } else if (!this.formValidate[i].ztdMachineNo) {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的机台号");
          return false;
        } else if (!this.formValidate[i].ztdWorkshopId) {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的所属车间");
          return false;
        } else if (!this.formValidate[i].ztdMachineBrand) {
          this.$Message.warning("请填写第" + (i + 1) + "台设备的品牌");
          return false;
        }
      }
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/editDevice",
        method: "post",
        data: this.formValidate
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("编辑成功");
            this.editState = !this.editState;
            this.getDeviceMessage();
          } else {
            this.$Message.warning(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancle() {
      this.editState = !this.editState;
    },
    workShopChange(index) {
      this.formValidate[index].ztdWorkshopName = name;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-box
  .modal-content
  form
  /deep/
  .ivu-form-item
  /deep/
  .ivu-form-item-content {
  margin-left: 0px !important;
  .label-text {
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #222;
  }
  .ivu-radio-group {
    margin-top: 10px;
    display: flex;
    .ivu-radio-wrapper {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #222;
    }
  }
}
.modal-box
  .modal-content
  form
  /deep/
  .ivu-form-item
  /deep/
  .ivu-form-item-label {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 500;
  color: #222;
}
.modal-box .modal-content form {
  .mc-info-title {
    height: 20px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    display: flex;
    margin: 28px 0 17px 0;
    &:nth-child(1) {
      margin-top: 0px;
    }
  }
}
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .formValidate {
    .action-area {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .actBtn {
        margin: 0px 10px 30px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: rgba(99, 115, 129, 1);
        cursor: pointer;
      }
      .actBtn :hover {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2980b9;
        cursor: pointer;
      }

      .cancle :hover {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2980b9;
        cursor: pointer;
      }
      .save :hover {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #2980b9;
        cursor: pointer;
      }
    }
    .basicInfo {
      margin-bottom: 130px;
      .infoTitle {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }
      .infoDetail {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 56px;
            line-height: 56px;
            padding-left: 140px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
          .ivu-form-item-error-tip {
            position: absolute;
            top: 60%;
            left: 15px;
            line-height: 1;
            padding-top: 6px;
            color: #ed4014;
            /* right: 2px; */
            z-index: 1;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0 15px;
          line-height: 56px;
          height: 56px;
          background: #f5f5f5;
          text-align: left;
          //为适应1366修改
          width: 130px !important;
        }
        .ivu-row {
          border-bottom: 1px solid #dfe3e8;
        }
      }
      // .infoDetail >>> .ivu-form-item >>>.ivu-form-item-content{
      //             margin-left:0px !important;
      //         }
    }
  }
  .otherInfo {
    .infoTitle {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
    }
    .infoDetail {
      .otherForm {
        .ivu-form-item {
          // margin:10px 0;
        }
        .ivu-form-item /deep/ .ivu-form-item-content {
          margin-left: 0px !important;
          height: 56px;
          line-height: 56px;
          .textfont {
            height: 56px;
            line-height: 56px;
            padding-left: 160px;
          }
          input {
            border: none;
          }
          .ivu-select-selection {
            border: none;
          }
        }
        .ivu-form-item /deep/ .ivu-form-item-label {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          // border-bottom:1px solid #DFE3E8;
          padding: 0 15px;
          line-height: 56px;
          width: 180px !important;
          height: 56px;
          height: 56px;
          background: #f5f5f5;
          text-align: left;
        }
      }
      .ivu-row {
        border-bottom: 1px solid #dfe3e8;
      }
    }
  }
}
</style>
<style lang="scss">
.jixiuModal,
.weixiuModal {
  .ivu-form-item {
    .ivu-form-item-content {
      margin-left: 0px !important;
    }
  }
  .ivu-modal-footer {
    border: none;
    padding-top: 0px;
  }
  .repairBtn {
    margin-top: 30px;
    margin-left: 120px;
    .el-button {
      margin-right: 100px;
    }
  }
  .weixiuBtn {
    margin-left: 50px;
    .el-button {
      &:nth-child(1) {
        margin-right: 100px;
      }
    }
  }
  .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner {
    border-color: #3095fd;
    background-color: transparent;
  }
  .ivu-steps-item.ivu-steps-status-process .ivu-steps-head-inner span {
    color: #3095fd;
  }
}
</style>
